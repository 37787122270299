import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Select} from "antd"
import { IptvInput } from "../input"
import { ApiCalls } from "../../shared/ApiCalls";
import { checkAuthorization, domainUrl } from "../../shared";

const AddVendorUser = ({visible, onClose, editvendor, getVendorApplications}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    useEffect(()=>{
        if(visible && editvendor){
            EditVendorApi(editvendor)
        }else{
            form.resetFields()
        }
    },[visible, editvendor])

    const AddUpdateCreateVendor = async (formData) => {
        setLoading(true)
        try {
            const result = await ApiCalls.VendorUserApi.AddUpdateCreateVendor({...formData,id:editvendor})
            setLoading(false);
            if(result){
                onClose();
                getVendorApplications();
            }
        } catch (error){
            console.error("Error updating data",error)
        }
    }

    // edit vendor api
    const EditVendorApi = (id) => {
        const {userToken}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/editVendorUser/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.success)
                {
                    console.log("Edit vendor", result) 
                    form.setFieldsValue(result?.vendorUser);
                }
            else
            {
                throw  'error'
            }
        })
        .catch(error => {alert(error)})
    }

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            AddUpdateCreateVendor(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

  return (
    <div>
        <Drawer
            title={editvendor?'Edit vendor User':'Add Vendor User'}
            onClose={onClose}
            open={visible}
            width={700}
            footer={
                <Space className="w-100">
                    <Button 
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        onClick={()=>form.submit()}
                        loading={loading}
                    >
                        {
                            editvendor?'Update' : 'Save'
                        }
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            name='name'
                            label='Vendor name'
                            required
                            message='Please enter name'
                            placeholder='e.g Jhone'
                            value={form.getFieldValue('name')}
                            disabled={editvendor? true : false}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            label='Password'
                            type='password'
                            name='password'
                            required
                            message={()=>{}}
                            placeholder='e.g A@123456'
                            value={form.getFieldValue("password") || ''}
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                            validator={({ getFieldValue }) => ({
                                validator: (_, value) => {
                                    const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                    if (!reg.test(value)) {
                                        return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                            })}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            name='token'
                            label='Total Token'
                            required
                            message='Please enter token!'
                            placeholder='e.g 200'
                            value={form.getFieldValue('token')}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            name='email'
                            label='Email Address'
                            required
                            message='Please enter email!'
                            placeholder='e.g abc@example.com'
                            value={form.getFieldValue('email')}
                            disabled={editvendor? true : false}
                        />
                    </Col>
                </Row>
            </Form>
        </Drawer>
    </div>
  )
}

export {AddVendorUser}