import React from 'react'
import { Image, Modal, Space, Typography } from 'antd'

const SuccessModal = ({visiblesuccess,onClose}) => {
  return (
    <Modal width={400}  className='shadow-c modal'  open={visiblesuccess} onOk={onClose} onCancel={onClose} centered footer={null}>
        <Space direction='vertical' className='w-100 py-3' align='center'>
            <Image src='/assets/icons/success.png' width={50} preview={false} />
            <Typography.Title level={5} className='m-0'>
                Success
            </Typography.Title>
            <Typography.Text className='fs-13'>
                Boxes are updated successfully
            </Typography.Text>
        </Space>
    </Modal>
  )
}

export {SuccessModal}