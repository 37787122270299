import { Form, Input, Typography } from 'antd';
export const IptvInput = ({ name, label, type, size, disabled, required, message, value, placeholder, textArea, validator, ...props }) => {
    return (
        <Form.Item
            name={name}
            label={<Typography.Text className='text-input'>{label}</Typography.Text>}
            rules={validator ? [
                {
                    required: required,
                    message: message,
                },
                validator
            ] : [
                {
                    required: required,
                    message: message,
                },
            ]}
        >
            {
                textArea ?
                    <Input.TextArea
                        placeholder={placeholder || ''}
                        value={value || ''}
                        {...props}
                    /> :
                type==='password' ?
                    <Input.Password
                        placeholder={placeholder || ''}
                        value={value || ''}
                        size={size || 'middle'}
                        disabled={disabled || false}
                        {...props}
                        />:
                    <Input
                        type={type || 'text'}
                        placeholder={placeholder || ''}
                        value={value || ''}
                        size={size || 'middle'}
                        disabled={disabled || false}
                        {...props}
                    />
            }
        </Form.Item>
    )
}