import React, { useState } from 'react'
import { Card, Space, Typography, Modal, Upload, Image, Row, Form, Col, Button } from 'antd'
import { IptvCheckbox } from '../checkbox'
import { TokenUrl, Urldomain } from '../../shared';
import { SuccessModal } from '../SuccessModal';
const { Dragger } = Upload;

const FileEditModal = ({editmodal,onClose}) => {

    const [form] = Form.useForm()
    const [ visiblesuccess, setVisibleSuccess ] = useState(false)

    const props = {
        name: 'file',
        multiple: false,
        action: 'importDevices',
    //     headers: {
    //       Authorization: `Bearer ${TokenUrl}`,
    //   },
        onChange(info) {
            const { file } = info;
            if (file.status !== 'uploading') {
                console.log(file, info.fileList);
                form.setFieldsValue({...form.getFieldsValue(),file:file?.originFileObj})
              }
        },
      }

  return (
    <div>
        <Modal width={700} title='Edit details' className='shadow-c modal'  open={editmodal} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Card
                className='radius-12 border0'
                actions={[
                    <Space className="w-100 px-3" style={{justifyContent:'center'}}>
                        <Button 
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            onClick={()=>{setVisibleSuccess(true);onClose()}}
                        
                        >
                            Upload File
                        </Button>
                    </Space>
                ]}
            >
                <Space direction='vertical' size={5} className='w-100'>
                    <Row gutter={[12,12]} justify={'center'}>
                        <Col lg={{span: 10}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                            <div>
                                <Dragger {...props} className="dragcs w-50">
                                    <p className="ant-upload-drag-icon">
                                        <Image src='/assets/icons/upload.png' width={'41.6px'} preview={false} />
                                    </p>
                                    <p className="ant-upload-text grayish fs-13">Import csv file</p>
                                </Dragger>
                            </div>
                        </Col>
                        <Col span={24} >
                            <Typography.Text 
                                strong 
                                className='brand-color center'
                            >
                                Maximum 200 devices import at one time
                            </Typography.Text>
                        </Col>
                    </Row>
                </Space>
            </Card>
        </Modal>
        <SuccessModal 
            visiblesuccess={visiblesuccess}
            onClose={()=>setVisibleSuccess(false)}
        />
    </div>
  )
}

export {FileEditModal}