import { domainUrl } from "../../constants/apiconstants/domainUrl";
import { message } from "antd";
import { checkAuthorization } from "../../helpers";

const AddUpdateCreateVendor = (formData) => {
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append(
        "Content-Type", "application/json",
    );
    myHeaders.append('Authorization', userToken);

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(formData),
        redirect: 'follow'
    };
    let url = formData.id ? '/updateVendorUser' :'/createVendorUser'
    return (
        fetch(domainUrl + url, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success('Android Box Created Successfully !!');
                return 1
            } else {
                message.error('Something went wrong');
                return 0
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
            return 0
        })
    )
};


const DeleteProductApi=(deletevendor)=>{
    const {userToken} = checkAuthorization();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type","application/json")
    myHeaders.append('Authorization', userToken);
    var requestOptions = {
      method:'DELETE',
      headers: myHeaders,
      redirect:'follow'
    }
    return(
        fetch(domainUrl + `/deleteVendorUser/${deletevendor}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result?.success) {
                message.success('Vendor Deleted Successfully !!');
            } else {
                message.error('Something went wrong');
            }
        })
        .catch(error => {
            message.error(error.message || 'Something went wrong');
        })
    )
  }

const VendorUserApi = {
    AddUpdateCreateVendor,
    DeleteProductApi
}

export {VendorUserApi}