import { AddEditBoxApi } from './AddEditBoxApi'
import { MasterPortalApi } from './MasterPortalApi'
import { VendorUserApi } from './VendorUserApi'
import { ChangePassApi } from './ChangePassApi'
const ApiCalls={
    AddEditBoxApi,
    MasterPortalApi,
    VendorUserApi,
    ChangePassApi
}
export {ApiCalls}