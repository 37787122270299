import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation} from 'react-router-dom'
import './index.css'
import { Layout, Menu, Image, Space } from 'antd';
import { Dashboard, ServerPortal, TransactionReport, AddEditBox, VendorUser } from '../';
import { UserProfile } from '../../components/Header';
import { MasterPortalDrawer } from '../../components/MasterPortalDrawer';
import { ChangePassword } from '../../components';
const { Header, Sider, Content } = Layout

const SideBar = () => {
    let navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [items, setItems] = useState([])
    const [currentTab, setCurrentTab]= useState('1')
    const [visible, setVisible] = useState(false)
    function getItem(label, key, icon, onClick, children, type) {
      return { key, icon, onClick, children, label, type }
    }
  
  
    useEffect(()=>{
      let tab= location?.pathname?.split("/")[1]
      tab= tab==='' ? '1':
          tab==='addeditbox' ? '2':
          tab==='vendoruser' ? '3':
          tab==='serverportal' ? '4':
          tab==='masterportal' ? '5':
          tab==='transactionreport' ? '6':
          '1'
      setCurrentTab(tab)
    }, [location])
  
  
    useEffect(() => {
      let itemsArr = []
        itemsArr.push(getItem('Dashboard', '1', 
            <img src="/assets/icons/dashboard.png" width={'20px'} preview={false} alt="" />, 
            () => { navigate("/", { replace: true }) }))
  
        itemsArr.push(getItem('Add/Edit Box', '2', 
            <img src="/assets/icons/category.png" width={'20px'} preview={false} alt="" /> , 
            () => { navigate("/addeditbox", { replace: true }) }))
  
        itemsArr.push(getItem('Vendor Users', '3', 
            <img src="/assets/icons/vendoruser.png" width={'20px'} preview={false} alt="" /> , 
            () => { navigate("/vendoruser", { replace: true }) }))
  
        itemsArr.push(getItem('Server Portal', '4', 
            <img src="/assets/icons/serverportal.png" width={'20px'} preview={false} alt="" /> , 
            () => { navigate("/serverportal", { replace: true }) }))
  
        itemsArr.push(getItem('Master Portal', '5', 
            <img src="/assets/icons/masterportal.png" width={'20px'} preview={false} alt="" /> , 
            () => { navigate("/masterportal", setVisible(true)) }))
  
        itemsArr.push(getItem('Transaction Report', '6', 
            <img src="/assets/icons/transactionrep.png" width={'20px'} preview={false} alt="" /> , 
            () => { navigate("/transactionreport", { replace: true }) }))
        setItems([...itemsArr])
      }, [navigate])
    return (
      <Layout style={{ height: '100vh'}}>
        <Sider trigger={null} collapsible collapsed={collapsed} style={{ height: '100vh', overflowY: 'auto' }}>
          <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
            <Image
              style={{width: collapsed?"100%":'100px'}}
              height={'auto'}
              src="./assets/logo-w.png"
              alt='Store App Admin Panel'
              
              preview= {false}
            />
          </div>
          <Menu
            defaultSelectedKeys={['1']}
            selectedKeys={[currentTab]}
            mode="inline"
            theme="dark"
            inlineCollapsed={collapsed}
            items={items}
            className='listitem'
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{
              padding: 0,
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <div style={{ width: '95%', display: 'flex', justifyContent: 'space-between' }}>
              
                  <div className= 'trigger border-0-btn' onClick={() => setCollapsed(!collapsed)}>
                    {
                      collapsed ? 
                      <Image src='/assets/icons/collaps.png' width={'35.46px'} preview={false} />
                      : 
                      <Image src='/assets/icons/collaps.png' width={'35.46px'} preview={false} />
                    }
                  </div>
              <Space size={20}>
                <UserProfile />
              </Space>
            </div>
          </Header>
  
          <Content
            className=" scroll-bar"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
              overflowY: 'auto'
            }}
          >
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/addeditbox" element={<AddEditBox />} />
                <Route path="/vendoruser" element={<VendorUser />} />
                <Route path="/serverportal" element={<ServerPortal />} />
                <Route path="/masterportal" element={<Dashboard />} />
                <Route path="/transactionreport" element={<TransactionReport />} />
                <Route path="/changepassword" element={<ChangePassword />} />
              </Routes>
          </Content>
        </Layout>
        <MasterPortalDrawer 
          visible={visible}
          onClose={()=>setVisible(false)}
        />
      </Layout>
    )
}

export default SideBar