import react, { useState } from "react"
import { Form, Image, Button, Space, Row, Col, Typography, Checkbox } from "antd"
import {  StoreInput } from '../../components'
import "./index.css"
import { useDispatch, useSelector } from "react-redux"
import { actionsApi } from "../../shared"

const {Title}= Typography

const Login = () => {

    const appDispatcher = useDispatch()
    const [form] = Form.useForm()
    const { loading } = useSelector(state => state?.login)
    const [forgotPassword, setForgotPassword]= useState(false)
    const login = () => {
        if(forgotPassword)
            appDispatcher(actionsApi?.forgotPassword(form.getFieldsValue(['email'])))
        else
            appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))

    }

    return (
        <div 
            className='login-form-cover'
            >
            <div className="center">
                <Row className="login-form border-grey">
                    <Col md={12} sm={24} xs={24}>
                        <Space 
                            direction='vertical' 
                            className='py-5 my-3 px-4 width-100'
                            size={15}
                            >
                            <div>
                                <div className="justify-center">
                                    <Image
                                        src={"./assets/logo-b.png"}
                                        alt='Store App Admin Panel'
                                        preview= {false}
                                        width={'130px'}
                                    />
                                </div>
                                <Title 
                                    level={5} 
                                    className="text-center text-blue-dark my-0 pt-3"
                                    >
                                    Login to access your dashboard
                                </Title>
                            </div>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    remember: true,
                                }}
                                style={{ width: '100%' }}
                                onFinish={login}
                            >
                                <Row>
                                    <Col span={24}>
                                        <StoreInput
                                            name="email"
                                            label="Email"
                                            required
                                            message='please enter username'
                                            size='large'
                                            value={form.getFieldValue("email") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <StoreInput
                                            name="password"
                                            type='password'
                                            label="Password"
                                            size='large'
                                            required
                                            message='please enter password'
                                            value={form.getFieldValue("password") || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Checkbox  className="mb-3">Remember me</Checkbox>
                                    </Col>
                                    <Col span={24}>
                                        <Button 
                                            type="primary"
                                            htmlType="submit" 
                                            size="large"
                                            block
                                            loading={loading}
                                            >
                                            Login
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    </Col>
                    <Col md={12} sm={0} xs={0}
                        className="bg-gray2 flex-col-align-end"
                        >
                            <Space direction="vertical" className="px-4" size={0} align="center" style={{justifyContent:"center",height:"100%"}}>
                                <Image src="/assets/login-right.png" className="w-100" preview={false} />
                                <Title 
                                    level={5} 
                                    className="text-center gray-color"
                                    >
                                    Version 1.1.6 Beta Release
                                </Title>
                            </Space>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
export {Login}