
//login
import { login, forgotPassword, loginResponse, loginClear, AuthCheck } from "./action/Login"
//dashboard
import { getAddBoxDetail, changeAddboxDetailFilters } from "./action/addandbox"
import { getAddVendorDetail, changeAddvendorDetailFilters } from "./action/mastervendor"


export const actionsApi = {
    login, 
    forgotPassword,
    loginResponse, 
    loginClear, 
    AuthCheck, 
    //addandroidbox 
    // getBoxStatistics,
    getAddBoxDetail, 
    changeAddboxDetailFilters,
    // addmastervendor
    getAddVendorDetail,
    changeAddvendorDetailFilters
}
