import React from 'react'
import { Card, Col, Row, Typography,Space, Image, theme } from 'antd'

const { useToken } = theme;
const { Title, Text } = Typography
const CardComponent = () => {
    const { token } = useToken();

    const data = [
        {
            title:'25.1k',
            subtitle:'Total devices',
            img:'totaldev.png',
        },
        {
            title:'2,435k',
            subtitle:'Remaining token',
            img:'remaintoken.png',
        },
        {
            title:'2,435k',
            subtitle:'Total Vendor User',
            img:'totalvendor.png',
        },
    ]
  return (
    <div>
        <Row gutter={[24,24]}>
            {
                data?.map((cdata,c)=>
                <Col xs={24} sm={24} md={12} lg={12} xl={6} key={c}>
                    <Card style={{borderColor: token.borderCardcolor,borderRadius: token.borderCardrad,boxShadow: token.cardshadow,width:"100%"}}>
                        <Space size={80} style={{justifyContent:"space-between"}} className='w-100'>
                            <div>
                                <Text className='text-input' strong>{cdata?.subtitle}</Text>
                                <Title level={3} className='text-blue my-0'>{cdata?.title}</Title>
                            </div>
                            <Image src={'/assets/icons/'+cdata?.img} width={'34px'} />
                        </Space>
                    </Card>
                </Col>
                )
            }
        </Row>
    </div>
  )
}

export {CardComponent}