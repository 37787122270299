const methodsUrl = [
    {
        name: 'Url 1',
        id : 1,
    },
    {
        name: 'Url 2',
        id : 2,
    },
    {
        name: 'Url 3',
        id : 3,
    },
    {
        name: 'Url 4',
        id : 4,
    },
    {
        name: 'Url 5',
        id : 5,
    },
]

const methodUrlType = [
    {
        name: 'M3U',
        id:1
    },
    {
        name: 'Stalker',
        id:2
    },
    {
        name: 'Xtreme Code API',
        id:3
    },
]

const methodAllow = [
    {
        name: 'Allowed',
        id: 'Allowed'
    },
    {
        name: 'Not Allowed',
        id: 'Not Allowed'
    },
]

export {methodsUrl, methodUrlType, methodAllow}