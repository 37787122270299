import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Select, message} from "antd"
import { IptvInput } from "../input"
import { ActivityLogModal } from '../ActivityLogModal'
import './index.css'
import { FileDoneOutlined } from "@ant-design/icons"
import { ApiCalls } from "../../shared/ApiCalls"
import FormItem from "antd/es/form/FormItem";
import { domainUrl } from "../../shared/constants"
import { checkAuthorization } from "../../shared/helpers"
import { Iptvselect } from "../Iptvselect"
import { methodUrlType, methodsUrl } from "../../shared/lookups"
const { Option } = Select;

const MasterPortalDrawer = ({visible, onClose}) => {
    const [form] = Form.useForm()
    const [vendoridData, setVendorIdData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [ loading, setLoading ] = useState(false)
    const [visiblelog, setVisibleLog] = useState(false)
    const [ displayinputs, setDisplayInputs ] = useState(false)

    const InputDisplay = (e) => {
        setDisplayInputs(e==='3'? true : false)
    }
   

    useEffect(()=>{
        if(visible){
            fetchVendorIdData()
        }
    },[visible])


    const createMasterPortal = async(formData) => {
        setLoading(true)
        try{
            const result = await ApiCalls.MasterPortalApi.createMasterPortal(formData)
            setLoading(false);
            if(result){
                onClose();
                form.resetFields();
            }
        } catch(error){
            console.error('Error add master portal data', error)
        }
    }

    const fetchVendorIdData = () => {
        const {userToken} = checkAuthorization()
        fetch(domainUrl + "/vendorDropDown", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: userToken
            }
        })
        .then(response => response.json())
        .then(result => {
            console.log('Vendor id', result?.vendor)
            if (result?.success) {
                const data = result?.vendor.map(vendor => ({
                    value: vendor.id,
                    label: vendor.name,
                }));
                setVendorIdData(data);
            }
        })
        .catch(error => {
            message.error('Failed to fetch Vendor Id');
        });
    };

    
    
    const handleBoxChange = (value) => {
        setSelectedOption(value);
        const vendorID = form.getFieldValue('vendorID');
        console.log('Vendor ID:', vendorID);
    };
    
    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            createMasterPortal(formData);
            setDisplayInputs(false);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

  return (
    <div>
        <Drawer
            title='Master portal'
            onClose={onClose}
            open={visible}
            className="drawer-cs"
            width={700}
            extra={
                <Button
                    type='primary'
                    icon={<FileDoneOutlined />}
                    onClick={()=> setVisibleLog(true)}
                    className='btn-color-other'
                >
                    Activity log
                </Button>
            }
            footer={
                <div className="w-100 space-between-center">
                    <Space className="w-100">
                        <Button 
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            type="primary"
                            onClick={()=> form.submit()}
                            loading={loading}
                        >
                            Save
                        </Button>
                    </Space>
                    <Button  
                        type="primary"
                    >
                        Update to All devices
                    </Button>
                </div>
            }
        >
            <div className="flex-col-Space-between height-100">
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Row gutter={16}>                       
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <Iptvselect
                                label= 'Select URL'
                                name="selectUrl" 
                                required
                                message='Please choose select url!'
                                placeholder='Select URL'
                                value={form.getFieldValue('selectUrl') || ''}
                                options={methodsUrl}
                            />
                        </Col>
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <Iptvselect 
                                label= 'URL Type'
                                name="urlType" 
                                required
                                message='Please choose url type!'
                                placeholder='Select URL Type'
                                value={form.getFieldValue('urlType') || ''}
                                onChange={InputDisplay}
                                options={methodUrlType}
                            />
                        </Col>
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <IptvInput
                                name='url'
                                label='URL'
                                placeholder='e.g www.example.com'
                                required
                                message='Please add url'
                                value={form.getFieldValue('url')}
                            />
                        </Col>
                        <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                            <FormItem
                                label='Vendor name'
                                name='vendorID'
                            >
                                <Select
                                    value={selectedOption}
                                    onChange={handleBoxChange}
                                    placeholder="Choose vendor name"
                                    style={{ width: "100%" }}
                                >
                                    {vendoridData.map(options => (
                                        <Option key={options.value} value={options.value}>
                                            {options.label}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </Col>
                        {
                            displayinputs?
                                <>
                                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                        <IptvInput
                                            label='Username'
                                            name='userName'
                                            placeholder='e.g Jhone'
                                            required
                                            message='Please enter url'
                                            value={form.getFieldValue("userName") || ''}
                                        />
                                    </Col>
                                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                        <IptvInput
                                            label='Password'
                                            type='password'
                                            name='password'
                                            required
                                            message={()=>{}}
                                            placeholder='e.g A@123456'
                                            value={form.getFieldValue("password") || ''}
                                            rules={[
                                                {
                                                    required: true,
                                                }
                                            ]}
                                            validator={({ getFieldValue }) => ({
                                                validator: (_, value) => {
                                                    const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                                    if (!reg.test(value)) {
                                                        return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                                    } else {
                                                        return Promise.resolve();
                                                    }
                                                }
                                            })}
                                        />
                                    </Col>
                                </>
                            :
                            <></>
                        }
                    </Row>
                </Form>
            </div>
        </Drawer>
        
        <ActivityLogModal
            visiblelog={visiblelog}
            onClose={()=>setVisibleLog(false)}
        />
        
    </div>
  )
}

export {MasterPortalDrawer}