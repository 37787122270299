import React, { useState } from 'react'
import { Card, Col, Row, Typography,Space, Image, Form, Dropdown, Table, Button } from 'antd'
import { ActionButton, AddEditDrawer, AlertModal, IptvSearch, ModuleTopHeading } from '../../components'
import { DeleteOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons';
const { Title, Text } = Typography

const suffix = (
    <Image src='/assets/icons/search-ic.png' width={'14.86px'} />
  );

const AppTable = () => {
    const [form] = Form.useForm()

    const [ visible, setVisible ] = useState(false)
    const [ visiblemodal, setVisibleModal] = useState(false)
    const [ dashname, setDashName ] = useState()
    const items = [
        {
            label: <a href="#">A-Z</a>,
            key: '0',
        },
        {
            label: <a href="#">Z-A</a>,
            key: '1',
        },
        {
            label: <a href="#">Registration date</a>,
            key: '2',
        },
    ];
    

      const columns = [
        {
            title: <Text>DEVICES NAME</Text>,
            dataIndex: 'devname',
            key: 'devname',
        },
        {
            title: <Text>MAC ADDRESS</Text>,
            dataIndex: 'macaddress',
            key: 'macaddress',
        },
        {
            title: <Text>VENDOR NAME</Text>,
            dataIndex: 'vendorName',
            key: 'vendorName',
        },
        {
            title: <Text>TIME STAMP</Text>,
            dataIndex: 'remainingtoken',
            key: 'remainingtoken',
        },
        {
            title: <Text>ACTION</Text>,
            key: 'action',
            align:'center',
            fixed: 'right',
            width: 100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit Box'
                        icon={<EditOutlined className='green-color'/>}
                        onClick={()=>{setVisible(true)}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Box'
                        icon={<DeleteOutlined className='danger-color'/>}
                        onClick={()=>{setVisibleModal(true); setDashName(row?.devname)}}
                        type='ghost'
                        danger
                    />
                </Space>
            ),
          },
      ];
      const data = [
        {
            key: '1',
            devname: <Text>AD12</Text>,
            macaddress: <Text>00:B0:D0:63:C2:26</Text>,
            vendorName: <Text>Nazli Hasan</Text>,
            remainingtoken: <Text>23/02/2023 15:03</Text>
        },
        {
            key: '2',
            devname: <Text>AD13</Text>,
            macaddress: <Text>00:B0:D0:63:C2:26</Text>,
            vendorName: <Text>Nazli Hasan</Text>,
            remainingtoken: <Text>23/02/2023 15:03</Text>
        },
        {
            key: '3',
            devname: <Text>AD14</Text>,
            macaddress: <Text>00:B0:D0:63:C2:26</Text>,
            vendorName: <Text>Nazli Hasan</Text>,
            remainingtoken: <Text>23/02/2023 15:03</Text>
        },
        {
            key: '4',
            devname: <Text>AD15</Text>,
            macaddress: <Text>00:B0:D0:63:C2:26</Text>,
            vendorName: <Text>Nazli Hasan</Text>,
            remainingtoken: <Text>23/02/2023 15:03</Text>
        },
        {
            key: '5',
            devname: <Text>AD16</Text>,
            macaddress: <Text>00:B0:D0:63:C2:26</Text>,
            vendorName: <Text>Nazli Hasan</Text>,
            remainingtoken: <Text>23/02/2023 15:03</Text>
        },
        {
            key: '6',
            devname: <Text>AB17</Text>,
            macaddress: <Text>00:B0:D0:63:C2:26</Text>,
            vendorName: <Text>Nazli Hasan</Text>,
            remainingtoken: <Text>23/02/2023 15:03</Text>
        },
        {
            key: '7',
            devname: <Text>AD18</Text>,
            macaddress: <Text>00:B0:D0:63:C2:26</Text>,
            vendorName: <Text>Nazli Hasan</Text>,
            remainingtoken: <Text>23/02/2023 15:03</Text>
        },
        {
            key: '8',
            devname: <Text>AD19</Text>,
            macaddress: <Text>00:B0:D0:63:C2:26</Text>,
            vendorName: <Text>Nazli Hasan</Text>,
            remainingtoken: <Text>23/02/2023 15:03</Text>
        },
      ];

    
  return (
    <div>
        <Card className='radius-12 border0'>
            <Row gutter={[12,12]}>
                <Col md={12}>
                    <ModuleTopHeading
                        name='Latest registered devices'
                    />
                </Col>
                <Col md={12}>
                    <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                        <IptvSearch
                            name="search"
                            size='large'
                            value={form.getFieldValue('search') || ''}
                            suffix={suffix}
                            placeholder='Search'
                        />
                        <Dropdown
                            menu={{
                            items
                            }}
                            trigger={['click']}
                            arrow
                            icon={<FilterOutlined />}
                        >
                            <Button
                                type='primary'
                                icon={<FilterOutlined/>}
                                size='large'
                            >
                                Filter
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={data} 
                        style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                        scroll={{x: 1000}}
                        pagination={{ 
                            hideOnSinglePage: true, 
                            total: 12,
                            // total: pagination?.totalRecords,
                            // pageSize: pagination?.pageSize,
                            // defaultPageSize: pagination?.pageSize,
                            // current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions:['20', '50', '100'],
                            //onChange: (pageNo, pageSize)=> call(pageNo, pageSize),
                            showTotal: total => <Button>Total: {total}</Button>
                        }}
                    />
                </Col>
            </Row>
        </Card>

        <AddEditDrawer
            visible={visible}
            onClose={()=> {setVisible(false)}}
        />
        <AlertModal
            visiblemodal={visiblemodal}
            dashname={dashname}
            onClose={()=>{setVisibleModal(false)}}
        />
    </div>
  )
}

export {AppTable}