import React,{ useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Image, Space, Row, Col, Form, Typography, Dropdown, Card, Table } from 'antd'
import { ActionButton, AddVendorUser, IptvSearch, ModuleTopHeading } from '../../components';
import { DeleteOutlined, EditOutlined, FilterOutlined } from '@ant-design/icons';
import { TableLoader, actionsApi } from '../../shared';
import { ConfirmVendorModal } from '../../components/ConfirmVendorModal';
const { Text } = Typography
const suffix = (
  <Image src='/assets/icons/search-ic.png' width={'14.86px'} />
);

const VendorUser = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [visible, setVisible]= useState(false);
  const { addvendorDetail, loadingAddVendorDetail, filters, pagination } = useSelector(state => state?.mastervendor)
  const [visiblemodal, setVisibleModal] = useState(false);
  const [editvendor, setEditVendor] = useState(null);
  const [ deletevendor, setDeleteVendor ] = useState(null)
  const [order, setOrder] = useState(1)  

  const items = [
      {
          label: <a href="#">A-Z</a>,
          key: '0',
      },
      {
          label: <a href="#">Z-A</a>,
          key: '1',
      },
      {
          label: <a href="#">Most downloads</a>,
          key: '2',
      },
      {
          label: <a href="#">Less downloads</a>,
          key: '3',
      },
  ];

  useEffect(() => {
    dispatch(actionsApi?.getAddVendorDetail({ ...filters, ...pagination, pageNo: 1 }))
    form.setFieldsValue({
        ...filters,
    })
    setOrder(filters?.order)

}, [])
const filter = (order) => {
    let data = form.getFieldsValue()
    dispatch(actionsApi?.getAddVendorDetail({ ...data, order, ...pagination, pageNo: 1 }))
    dispatch(actionsApi?.changeAddvendorDetailFilters({ ...data, order }))
}
const call = (pageNo = 1, pageSize = 10) => {
    dispatch(actionsApi?.getAddVendorDetail({ ...filters, pageNo, pageSize }))
}
const onClick = ({ key }) => {
    key = parseInt(key) + 1
    setOrder(key)
    filter(key)
};

  const columns = [
        {
            title: <Text>VENDOR NAME</Text>,
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: <Text>EMAIL ADDRESS</Text>,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: <Text>REMAINING TOKEN</Text>,
            dataIndex: 'token',
            key: 'token',
        },
        {
          title: <Text>ACTION</Text>,
          key: 'action',
          fixed:'right',
          width:100,
          align:'center',
          render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Edit Item'
                        icon={<EditOutlined className='green-color'/>}
                        onClick={()=>{setVisible(true);setEditVendor(row?.id)}}
                        type='ghost'

                    />
                    <ActionButton
                        title='Delete Item'
                        icon={<DeleteOutlined className='danger-color'/>}
                        onClick={()=>{setVisibleModal(true);setDeleteVendor({id: row?.id, name: row?.name})}}
                        type='ghost'
                        danger
                    />
                </Space>
          ),
        },
    ];


  return (
    <div>
        <Card className='radius-12 border0'>
            <Row gutter={[12,12]}>
                <Col md={12}>
                    <ModuleTopHeading 
                        name='Vendor Users'
                        onClick={()=>setVisible(true)}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Space align='baseline' className='w-100' size={10} style={{justifyContent:"end"}}>
                        <IptvSearch
                            name="search"
                            size='large'
                            value={form.getFieldValue('search') || ''}
                            suffix={suffix}
                            placeholder='Search'
                        />
                        <Dropdown
                                menu={{
                                items
                                }}
                                trigger={['click']}
                                arrow
                                icon={<FilterOutlined />}
                            >
                            <Button
                                type='primary'
                                icon={<FilterOutlined/>}
                                size='large'
                            >
                                Filter
                            </Button>
                        </Dropdown>
                    </Space>
                </Col>
                <Col span={24}>
                    <Table 
                        size='large'
                        columns={columns} 
                        dataSource={addvendorDetail} 
                        style={{border:'1.81193px solid #ECEEF7',borderRadius:20,padding:"9px 9px 0 9px"}}
                        scroll={{x: 1000}}
                        pagination={{
                            hideOnSinglePage: true,
                            total: pagination?.totalRecords,
                            pageSize: pagination?.pageSize,
                            defaultPageSize: pagination?.pageSize,
                            current: pagination?.pageNo,
                            size: "default",
                            pageSizeOptions: ['10', '20', '50', '100'],
                            onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                            showTotal: (total) => <Button>Total: {total}</Button>,
                        }}
                        loading={
                            {
                                ...TableLoader,
                                spinning: loadingAddVendorDetail
                            }
                        }
                    />
                </Col>
            </Row>
            <AddVendorUser
                visible={visible}
                editvendor={editvendor}
                getVendorApplications={call}
                onClose={()=> {setVisible(false);setEditVendor(null)}}
            />
            <ConfirmVendorModal
                visiblemodal={visiblemodal}
                deletevendor={deletevendor}
                getVendorApplications={call}
                onClose={()=>{setVisibleModal(false);setDeleteVendor(null)}}
            />
        </Card>
    </div>
  )
}

export {VendorUser}