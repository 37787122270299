import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Select } from "antd"
import { IptvInput } from "../input"
import { checkAuthorization, domainUrl } from "../../shared";
import FormItem from "antd/es/form/FormItem";
import { ApiCalls } from "../../shared/ApiCalls";
import { methodAllow, methodUrlType, methodsUrl } from "../../shared/lookups";
import { Iptvselect } from "../Iptvselect";

const { Option } = Select;

const AddEditDrawer = ({visible, onClose,editbox, getAllApplications }) => {
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)
    const [ displayinputs, setDisplayInputs ] = useState(false)

    const InputDisplay = (e) => {
        setDisplayInputs(e==='3'? true : false)
    }

    

    useEffect(() => {
        if (visible && editbox) {
            EditboxApi(editbox)
        } else {
            form.resetFields();
        }
    }, [visible, editbox]);

    const updateCreateApi = async (formData) => {
        setLoading(true);
        try {
            const result = await ApiCalls.AddEditBoxApi.updateCreateApi({...formData,id:editbox});
            setLoading(false);
            onClose();
            getAllApplications();
        } catch (error) {
            console.error("Error updating data:", error);
        }
    };

    //edit android api
    const EditboxApi = (id) => {
        const {userToken} = checkAuthorization();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append('Authorization', userToken);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/editAndroidBox/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.success)
                {
                console.log("Edit android", result)
                form.setFieldsValue(result?.androidBox) 
                
                }
            else
            {
                throw  'error'
            }
        })
        .catch(error => {alert(error)})
    }

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            updateCreateApi(formData);
            setDisplayInputs(false);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };


  return (
    <div>
        <Drawer
            title={editbox?'Edit Box':'Add Box'}
            onClose={onClose}
            open={visible}
            width={700}
            footer={
                <Space className="w-100">
                    <Button 
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        loading={loading}
                        onClick={()=> form.submit()}
                    >
                        {
                            editbox? 'Update box' : 'Save'
                        }
                    </Button>
                </Space>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                onFinish={onFinish}
            >
                <Row gutter={16}>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            label='Android Box Name'
                            name='boxName'
                            placeholder='e.g Jhone'
                            required
                            message='Please enter android box name'
                            value={form.getFieldValue("boxName") || ''} 
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            label='Serial number'
                            name='serialNo'
                            placeholder='e.g 1234'
                            required
                            message='Please enter serial number'
                            value={form.getFieldValue("serialNo") || ''} 
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <FormItem
                            label='Master Portal'
                            name='masterPortal'
                            rules={[
                                {
                                    required:true,
                                    message:'Please choose master portal'
                                }
                                
                            ]}
                        >
                            <Select
                                value={form.getFieldValue("masterPortal") || ''} 
                                placeholder="Choose updated url"
                                style={{ width: "100%" }}
                            >
                                <Option value={'Yes'}>
                                        Yes
                                </Option>
                                <Option value={'No'}>
                                        No
                                </Option>
                            </Select>
                        </FormItem>
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            label='MAC Address'
                            name='macAddress'
                            placeholder='e.g 00:B0:D0:63:C2:46'
                            required
                            message='Please enter mac address'
                            value={form.getFieldValue("macAddress") || ''} 
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const cleanedValue = inputValue.replace(/\W/g, '');
                                const formattedMacAddress = cleanedValue.match(/.{1,2}/g)?.join(':').slice(0, 17) || '';
                                form.setFieldsValue({ ...form?.getFieldsValue(), macAddress: formattedMacAddress });
                            }} 
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <Iptvselect
                            label= 'Update URL'
                            name="updateUrl" 
                            required
                            message='Please choose update url'
                            placeholder='Choose updated url'
                            value={form.getFieldValue('updateUrl') || ''}
                            options={methodAllow}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            label='Customer Name'
                            name='customerName'
                            placeholder='e.g Jhone'
                            required
                            message='Please enter customer name'
                            value={form.getFieldValue("customerName") || ''}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <Iptvselect
                            label= 'Select URL'
                            name="selectUrl" 
                            required
                            message='Please choose select url!'
                            placeholder='Select URL'
                            value={form.getFieldValue('selectUrl') || ''}
                            options={methodsUrl}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <Iptvselect 
                            label= 'URL Type'
                            name="urlType" 
                            required
                            message='Please choose url type!'
                            placeholder='Select URL Type'
                            value={form.getFieldValue('urlType') || ''}
                            onChange={InputDisplay}
                            options={methodUrlType}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <IptvInput
                            label='URL'
                            name='url'
                            placeholder='e.g www.example.com'
                            required
                            message='Please enter url'
                            value={form.getFieldValue("url") || ''}
                        />
                    </Col>
                    {
                        displayinputs?
                            <>
                                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                    <IptvInput
                                        label='Username'
                                        name='userName'
                                        placeholder='e.g Jhone'
                                        required
                                        message='Please enter url'
                                        value={form.getFieldValue("userName") || ''}
                                    />
                                </Col>
                                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                    <IptvInput
                                        label='Password'
                                        type='password'
                                        name='password'
                                        required
                                        message={()=>{}}
                                        placeholder='e.g A@123456'
                                        value={form.getFieldValue("password") || ''}
                                        rules={[
                                            {
                                                required: true,
                                            }
                                        ]}
                                        validator={({ getFieldValue }) => ({
                                            validator: (_, value) => {
                                                const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                                if (!reg.test(value)) {
                                                    return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            }
                                        })}
                                    />
                                </Col>
                            </>
                        :
                        <></>
                    }
                </Row>
            </Form>
        </Drawer>
    </div>
  )
}

export {AddEditDrawer}