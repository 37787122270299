import React, { useState } from 'react'
import { Image, Modal, Space, Typography, Button } from 'antd'
import { ApiCalls } from '../../shared/ApiCalls'
const { Title, Text } = Typography
const AlertModal = ({visiblemodal,onClose,deletebox, getAllApplications, showname, dashname}) => {

    const [ loading, setLoading ] = useState(false)

    const DeleteAndroidApi = async()=> {
        setLoading(true)
        const request = await ApiCalls.AddEditBoxApi.DeleteAndroidApi(deletebox?.id);
        setLoading(false);
        onClose();
        getAllApplications();
    }

  return (
    <div>
        <Modal width={500} className='shadow-c modal'  open={visiblemodal} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <Image src='/assets/icons/alert-ic.png' width={'70px'} preview={false} />
                <Title level={4} className='my-0'>Alert</Title>
                <Text className='text-input text-center'>Are you sure you want to permanently delete the Android box <strong>"{deletebox? deletebox?.customerName: showname? showname : dashname}"</strong></Text>
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        loading={loading}
                        onClick={DeleteAndroidApi}
                    >
                        Confirm
                    </Button>
                </Space>
            </Space>
        </Modal>
    </div>
  )
}

export {AlertModal}