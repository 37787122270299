import React, { useState } from 'react'
import { IptvInput } from '../input'
import { Row, Col, Button, Form, Typography, Space, Image } from 'antd'
import { ApiCalls } from '../../shared/ApiCalls'


const ChangePassword = () => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    
    const changePasswordPost = async(formData) => {
        setLoading(true)
        try {
            const result = await ApiCalls.ChangePassApi.changePasswordPost(formData)
            setLoading(false);
        } catch (error){
            console.error("Error change password data",error)
        }
    }

    const onFinish = async () => {
        try {
            const formData = await form.validateFields();
            changePasswordPost(formData);
        } catch (error) {
            console.error("Form validation error:", error);
        }
    };

  return (
    <div className="center">
        <Row className="login-form w-100 py-5 border-grey">
            <Col md={12} sm={24} xs={24}>
                <Space 
                    direction='vertical' 
                    className='py-5 my-2 px-4 width-100'
                    size={15}
                    >
                    <div className="justify-center mb-3">
                        <Image
                            src={"./assets/logo-b.png"}
                            alt='Store App Admin Panel'
                            preview= {false}
                            width={'100px'}
                        />
                    </div>
                    <Form
                        form={form}
                        layout="vertical"
                        style={{ width: '100%' }}
                        onFinish={onFinish}
                    >
                        <Row>
                            <Col span={24}>
                                <IptvInput
                                    type='password'
                                    name='oldPassword'
                                    label="Old Password"
                                    required
                                    message='please enter old password'
                                    size='large'
                                    value={form.getFieldValue("oldPassword") || ''}
                                />
                            </Col>
                            <Col span={24}>
                                <IptvInput
                                    type='password'
                                    name='newPassword'
                                    label="New Password"
                                    size='large'
                                    required
                                    message='please enter new password'
                                    value={form.getFieldValue("newPassword") || ''}
                                    
                                />
                            </Col>
                            <Col span={24}>
                                <Button 
                                    type="primary"
                                    htmlType="submit" 
                                    block
                                    loading={loading}
                                    size='large'
                                    >
                                    Save changes
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Space>
            </Col>
            <Col md={12} sm={0} xs={0}
                className="bg-gray2 flex-col-align-end"
                >
                    <Space direction="vertical" className="px-4" size={0} align="center" style={{justifyContent:"center",height:"100%"}}>
                        <Image src="/assets/login-right-2.png" className="w-100" preview={false} />
                        <Typography.Title 
                            level={5} 
                            className="text-center gray-color"
                            >
                            Version 1.1.6 Beta Release
                        </Typography.Title>
                    </Space>
            </Col>
        </Row>
    </div>
  )
}

export {ChangePassword}